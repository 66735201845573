import React from 'react';
import collabKartLogo from '../../assets/collabkart_logo.png';
import './footer.css'
import {Link} from "react-router-dom";
const Footer = () => {
   return (
      <div className="transformer__footer section__padding">
         <div className="transformer__footer-heading">
            <h1 className="gradient__text">Step in to the future of Influencer Marketing</h1>
         </div>

         <div className="transformer__footer-btn">
            <a href="https://ig.me/m/collabkart" target="_blank" rel="noopener noreferrer">
               <button type="button">DM Us Now</button>
            </a>
         </div>

         <div className="transformer__footer-links">
            <div className="transformer__footer-links_logo">
               <figure>
                  <img src={collabKartLogo} alt="Footer logo" />
                  <address>
                     <p>Collab Kart, All Rights Reserved</p>
                  </address>
               </figure>
            </div>

            <div className="transformer__footer-links_div">
               <h4>Links</h4>
               <ul>
                  <li><a href="https://www.instagram.com/collabkart/" target="_blank" rel="noreferrer">Instagram</a></li>
                  <li><a href="https://www.facebook.com/collabkart" target="_blank" rel="noreferrer">Facebook</a></li>
                  <li><a href="https://twitter.com/collabkart" target="_blank" rel="noreferrer">Twitter</a></li>
                  <li><a href="https://www.youtube.com/@collabkart" target="_blank" rel="noreferrer">YouTube</a></li>
               </ul>
            </div>

            <div className="transformer__footer-links_div">
               <h4>Company</h4>
               <ul>
                  <li><a href="#home">Terms & Conditions</a></li>
                  <li><a href="#home">Privacy Policy</a></li>
                  <li><a href="#home">Contact</a></li>
                  <li><Link to="/about">About</Link></li>
               </ul>
            </div>

            <div className="transformer__footer-links_div">
               <h4>Get in touch</h4>
               <ul>
                  <li><a href="https://maps.app.goo.gl/bXYHbMhXNbrh1ndV9" target="_blank" rel="noopener noreferrer">Hyderabad,India</a></li>
                  <li><a href="tel:+91xxx" target="_blank" rel="noopener noreferrer">+91-xxx</a></li>
                  <li><a href="mailto:collabkart@gmail.com">collabkart@gmail.com</a></li>
               </ul>
            </div>
         </div>
         <div className="transformer__footer-copyright">
            <p>© 2023 COLLAB KART. All rights reserved.</p>
         </div>
      </div>
   )
}
export default Footer
