import React from 'react';
import { Feature } from '../../components';
import './features.css';

const featuresData =[
   {
      title:'Global Influence ',
      text:'Join 1600+ Creators Worldwide, Spanning USA, Europe, India, and Beyond for Unmatched Audience Reach and Impact.'
   },

   {
      title:'Insightful Analytics',
      text:'Unlock Audience Interest Reports for Laser-Targeted Campaigns, Driving High Conversion Rates and Cost-Effective Marketing Solutions.'
   },

   {
      title:'High Returns',
      text:'Tap into a Collab Kart for Low-Cost, High-Impact Campaigns with Stellar Conversion Rates.'
   },

   {
      title:'Maximize Savings',
      text:'Achieve High-Impact Results at Minimal Expense with Our Budget-Friendly Solutions, Ensuring Cost-Effective Success for Your Campaigns.'
   }
]

const Features = () => {
   return (
      <div className="transformer__features section__padding" id="features">
         <div className="transformer__features-heading">
            <h1 className="gradient__text">
            The Future of Influencer Marketing is Collab Kart. Step into Tomorrow Today & Make it Happen.
            </h1>
            <p>Contact us to Get Started</p>
         </div>

         <div className="transformer__features-container">
            {featuresData.map((item,index)=>(
               <Feature title={item.title} text={item.text} key={item.title +index}></Feature>
            ))}

         </div>
      </div>
   )
}

export default Features
