import React from 'react';
import { Article } from '../../components';
import './blog.css'
import {Link} from "react-router-dom";
import blogData from '../../components/Blogs/blogsdata.json';
const Blog = () => {
   return (
      <div className="transformer__blog section__padding" id="blog">
         <div className="transformer__blog-heading">
            <h1 className="gradient__text">A lot is happening,<br/> We are blogging about it.</h1>
         </div>

         <div className="transformer__blog-container">
            <div className="transformer__blog-container_groupA">
                {blogData.slice(0, 1).map((blog, index) => (
                    <Article id={blog.id} date={blog.date} title={blog.title} discription={blog.content.slice(0,300)} />
                ))}
            </div>
            <div className="transformer__blog-container_groupB">
                {blogData.slice(1, 5).map((blog, index) => (
                    <Article id={blog.id} date={blog.date} title={blog.title}  discription={blog.content.slice(0,100)}/>
                ))}
            </div>
         </div>
          <div className= 'transformer__blog-explor-more'>
             <p> <Link to="/blogs">Explore more > </Link> </p>
          </div>
      </div>
   )
}

export default Blog
