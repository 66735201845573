import React from 'react';
import './cta.css'

const Cta = () => {
   return (
      <div className="transformer__cta section__margin">
         <div className="transformer__cta-content">
            <p>DM Us Now to Get Started</p>
            <h1>Register today & start exploring the endless possiblities.</h1>
         </div>
         <div className="transformer__cta-btn">
            <a href="https://forms.gle/RsxXyVrP6WkB6Bbg9" target="_blank" rel="noopener noreferrer">
               <button type="button">For Creators</button>
            </a>
            <a href="https://forms.gle/EaExHA5scnBr25uk8" target="_blank" rel="noopener noreferrer" style={{marginRight:"30px"}}>
            <button type="button">For Brands</button>
            </a>

         </div>
      </div>
   )
}

export default Cta
