import React, { Fragment, useState } from 'react';
import { RiMenu3Fill, RiCloseLine } from 'react-icons/ri'
import logo from '../../assets/collabkart_logo.png'
import './navbar.css'


const Menu = ({ menuItems }) => (
    <>
        {menuItems.map((item, index) => (
            <p key={index}><a href={item.link}>{item.label}</a></p>
        ))}
    </>
);

const Navbar = ({ menuItems }) => { // Destructure menuItems from props
    const [toggleMenu, setToggleMenu] = useState(false);

    return (
        <div className="transformer__navbar">
            <div className="transformer__navbar-links">
                <div className="transformer__navbar-links_logo" >
                    <a href='/'>
                        <img src={logo} alt="tranformer logo"></img>
                    </a>
                </div>
                <div className="transformer__navbar-links_container">
                    {/* Pass menuItems to Menu component */}
                    <Menu menuItems={menuItems} />
                </div>
            </div>
            {/*<div className="transformer__navbar-sign">*/}
            {/*    <p>Sign in</p>*/}
            {/*    <button type="button">Sign up</button>*/}
            {/*</div>*/}
            <div className="transformer__navbar-menu">
                {toggleMenu ?
                    <RiCloseLine color="#fff" size={27} onClick={() => setToggleMenu(false)} />
                    : <RiMenu3Fill color="#fff" size={27} onClick={() => setToggleMenu(true)} />
                }
                {
                    toggleMenu && (
                        <div className="transformer__navbar-menu_container scale-up-center">
                            <div className="transformer__navbar-menu_container-links">
                                {/* Pass menuItems to Menu component */}
                                <Menu menuItems={menuItems} />
                                {/*<div className="transformer__navbar-menu_container-links-sign">*/}
                                {/*    <p>Sign in</p>*/}
                                {/*    <button type="button">Sign up</button>*/}
                                {/*</div>*/}
                            </div>
                        </div>
                    )
                }
            </div>
        </div>
    )
}



export default Navbar;
