import React from 'react';
import { Link } from 'react-router-dom';
function TeamMember(props) {
    return (
        <>
            <li className='team__member'>
                <Link className='team__member__link' to={props.path}>
                    <figure className='team__member__pic-wrap' data-category={props.label}>
                        <img
                            className='team__member__img'
                            alt='asf'
                            src={props.src}
                        />
                    </figure>
                    <div className='team__member__info'>
                        <h5 className='team__member__name'>{props.name}</h5>
                        <p className='team__member__text'>{props.text}</p>
                    </div>
                </Link>
            </li>
        </>
    );
}

export default TeamMember;