import React from 'react';
import AboutHeroSection from "./AboutHeroSection";
import Team from './Team';
import Footer from "../../containers/footer/Footer";
import Navbar from "../navbar/Navbar";
import './About.css'


export default function About ()  {
    const menuItems = [
        { label: 'Home', link: '/', useHash: true },
        { label: 'Our Goal', link: '#goal', useHash: true },
        { label: 'Team', link: '#team', useHash: true },
        { label: 'Explore Blogs', link: '/blogs' }
    ];

    return (
        <div>
            <div className="gradient__bg">
                <Navbar menuItems={menuItems}/>
                <AboutHeroSection/>
            </div>
            <Team/>
            <Footer/>
            <div>

            </div>
        </div>
    );
  
}




