import React, {Component} from 'react'
import './AboutHeroSection.css'

export class AboutHeroSection extends Component  {
  render() {
    return (
        <div className='about-hero-container' id='goal'>
            <div className='about-hero-section2'>
                <div className='about-second50'>
                    <div className="about-title">
                        <h1 className="about-title_text">Changing the world through personalized
                            digital Advertisement.</h1>
                    </div>
                    <div className="about-p-text">
                        <p className="about-home_cover_p">Founded 1 years ago on the simple idea of creating innovative products that change
                                the online Advertisement,&nbsp;Collabkart offers groundbreaking technology that empowers everyone,
                                everywhere to imagine, create, and bring ease to digital marketing.</p>

                    </div>

                </div>
                <div className='about-first50'></div>
            </div>

        </div>
    )
  }
}

export default AboutHeroSection;

