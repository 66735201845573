import React, {Component} from 'react'
import './AboutHeroSection.css'
import './Team.css'
import TeamMember from "./TeamMember";

export class team extends Component  {
    render() {
        return (
        <div className='team' id='team'>
            <h1>Our team</h1>
            <div className='team__container'>
                <div className='team__wrapper'>
                    <ul className='team__members'>
                        <TeamMember
                            src='Team_img/Manish1_low.jpg'
                            name='Manish Yadav'
                            label='CEO'
                            text="Manish, a former IT professional with experience in advertising at Oracle, developed a keen interest in the industry's future. Driven by a desire to help small influencers and brands, he co-founded CollabKart with his college friends!"
                        />
                        <TeamMember
                            src='Team_img/govind_low.jpg'
                            name='Govind Sherawat'
                            label='CMO'
                            text="Govind's interest in influencer marketing led him to manage top creators' profiles, making him an expert in the field. With his IT background, he recognized the value of automation and co-founded CollabKart to make it accessible to everyone.!"

                        />
                        <TeamMember
                            src='Team_img/aditya1_low.jpg'
                            name='Aditya Kumar Singh'
                            label='CTO'
                            text="Aditya, a former Amazon IT professional with a strong command of his field, has a passion for creating solutions that simplify people's lives. This drive led him to co-found CollabKart, aiming to make life easier for influencers and brands."
                        />
                        <TeamMember
                            src='Team_img/riya.jpg'
                            name='Riya Gupta'
                            label='Creative Head'
                            text="Riya, a fashion graduate with experience in social media management for major brands, joined CollabKart to ensure smooth social media operations. She delivers fresh and engaging content daily, making our Facebook, Twitter, and Instagram accounts visually appealing."
                        />
                    </ul>
                </div>
            </div>
        </div>
        )
    }
}

export default team;

