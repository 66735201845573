import React from 'react';
import './blogpage.css'
import Navbar from '../../components/navbar/Navbar';
import blogsData from './blogsdata.json';
import { useParams } from 'react-router-dom';
const menuItems = [
    { label: 'Home', link: '/' },
    { label: 'Blogs', link: '/blogs' },
    { label: 'about', link: '/about' }
];



export default function BlogPage () {
    const { id } = useParams();
    const blog = blogsData.find(blog => blog.id === parseInt(id));

    return (
        <div>
            <div className="gradient__bg">
                <Navbar menuItems={menuItems}/>
            </div>
            <div className="transformer__blogpage section__padding" id="blogpage">
                <div >
                    <a href="/blogs"  rel="noopener noreferrer"
                       style={{padding: '1rem', marginRight: '20px'}}>
                        <button type="button" style={{padding: '1rem',background: '#60B3EB'}}>Back to Blogs</button>
                    </a>
                </div>
                <div className="transformer__blogpage-heading">
                    <h1 className="gradient__text">{blog.title}</h1>
                </div>

                <div>
                    <div className="transformer__blog-container_article-image">

                    <img src={require(`../../assets/${blog.imgUrl}`)} alt="blog"/>
                    </div>


                    <div className="blogpage-content">
                        <div>
                            <p>{blog.date}</p>
                            <p>{blog.content}</p>
                        </div>
                    </div>
                </div>
            </div>
            <div>
            </div>
        </div>

    );
}

