import React from 'react';
import Article from '../article/Article';
import './blogshomepage.css'
import Navbar from '../navbar/Navbar';
import Footer from '../../containers/footer/Footer';
import blogsData from './blogsdata.json';

import {Link} from "react-router-dom";
const menuItems = [
    { label: 'Home', link: '/' },
    { label: 'Blogs', link: '#blog' },
    { label: 'about', link: '/about' }
];

export default function BlogsHomePage ()  {
    return (
        <div>
            <div className="gradient__bg">
                <Navbar menuItems={menuItems}/>
            </div>
            <div className="transformer__blogshomepage section__padding" id="blog">
                <div className="transformer__blogshomepage-heading">
                    <h1 className="gradient__text">A lot is happening,<br/> We are blogging about it.</h1>
                </div>

                <div className="transformer__blogshomepage-container">
                    <div className="transformer__blogshomepage-container_groupB">

                            {blogsData.map(blog => (
                                <li key={blog.id}>
                                    <Link to={`/blog/${blog.id}`}><Article id={blog.id}  date={blog.date} title={blog.title} discription={blog.content.slice(0,100)} />
                                    </Link>
                                </li>
                            ))}

                    </div>
                </div>
            </div>
            <div>
            </div>
            <Footer/>
        </div>

    );
}
