import React from 'react';
import people from '../../assets/people.png';
import ai from '../../assets/ai2.png';

import './header.css'

const Header = () => {
   return (
      <div className="transformer__header section__padding" id="home">
         <div className="transformer__header-content">
            <h1 className="gradient__text">One Click Marketing Solution <br /> For All Brands</h1>
            <p>Automating creator-brand collaborations with our AI Powered ADs</p>

            <div className="transformer__header-content__input">
               <a href="https://forms.gle/RsxXyVrP6WkB6Bbg9" target="_blank" rel="noopener noreferrer" style={{ marginRight:'20px', width: '100%' }}>
                  <button type="button" style={{ background: '#60B3EB' }}>I AM A CREATOR</button>
               </a>
               <a href="https://forms.gle/EaExHA5scnBr25uk8" target="_blank" rel="noopener noreferrer" style={{ width: '100%' }}>
                  <button type="button" style={{ background: '#1972AE' }}>I AM A BRAND</button>
               </a>
            </div>

            <div className="transformer__header-content__people">
               <img src={people} alt="transformer people" />
               <p>1,600 creators subscribed around the globe</p>
            </div>
         </div>
         <div className="transformer__header-image">
            <figure>
               <img src={ai} alt="transformer ai" />
            </figure>
         </div>
      </div>
   )
}

export default Header
