import React from 'react'
import Feature  from '../../components/feature/Feature'
import './whatCollabKart.css'

const WhatCollabKart = () => {
   return (
      <div className="transformer__whattransformer section__margin" id="collabkart">
      <div className="transformer__whattransformer-feature">
        <Feature title="Welcome To CollabKart" text="Your Ultimate Destination for Brand-Creator Collaborations! Discover Seamless Partnerships, Amplify Your Brand's Reach, and Unleash Creativity Like Never Before!" />
      </div>
      <div className="transformer__whattransformer-heading">
        <h1 className="gradient__text">AI-Powered <br />Shoutouts & Collabs</h1>
        <p>Explore the Library</p>
      </div>
      <div className="transformer__whattransformer-container">
        <Feature title="Creators" text="Join our diverse community of 1600+ influencers to showcase your page, expand your reach, and unlock collaboration opportunities for brand elevation and growth." />
        <Feature title="Brands" text="Elevate your advertising strategy with AI Powered Ads, leveraging artificial intelligence to precisely match brands with creators, optimizing niche targeting for enhanced audience engagement and brand visibility." />
        <Feature title="Mutual Success" text="Brands & Creators Win Together! Invest in collaborative success with our platform, empowering brands and creators alike to thrive. Seamlessly connect, amplify reach, and maximize earnings through strategic partnerships and innovative opportunities." />
      </div>
    </div>
   )
}

export default WhatCollabKart
