import React from 'react';
import {instagram,facebook,youtube,twitter,tiktok} from './import.js'

import './brand.css'



const Brand = () => {
   return (
      <div className="transformer__brand section__padding">
         <figure>
            <a href="https://www.instagram.com/collabkart"><img src={instagram} alt="collabkart instagram" height="100%" width="100%"/></a>
         </figure>
         <figure>
            <a href="https://www.facebook.com/collabkart"><img src={facebook} alt="collabkart facebook" height="100%" width="100%"/></a>
         </figure>
         <figure>
         <a href="https://www.youtube.com/@collabkart"><img src={youtube} alt="collabkart youtube" height="100%" width="100%"/></a>
         </figure>
         <figure>
            <a href="https://twitter.com/collabkart"><img src={twitter} alt="collabkart twitter" height="100%" width="100%"/></a>
         </figure>
         <figure>
            <a href="https://vm.tiktok.com/collabkart"><img src={tiktok} alt="collabkart tiktok" height="100%" width="100%"/></a>
         </figure>
      </div>
   )
}

export default Brand
