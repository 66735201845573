import React from 'react';
import './article.css'
import {Link} from "react-router-dom";


const Article = ({id,date,title,discription}) => {
   return (
       <div className="transformer__blog-container_article">
           <div className="transformer__blog-container_article-image">
               <img src={require(`../../assets/Blog${id}.jpeg`)} alt="blog" />
           {/*    width 1820 height 1024*/}
           </div>
           <div className="transformer__blog-container_article-content">
                <div>
                   <p>{date}</p>
                   <h1>{title}</h1>
                    <p>{discription} <span>...</span></p>
                </div>
                 <Link to={`/blog/${id}`} ><p>Read Full Article</p></Link>
            </div>
      </div>
   )
}

export default Article
