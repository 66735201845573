import React from 'react';

import {Blog,Features,Footer,Header,WhatCollabKart} from '../../containers';
import {CTA,Brand,Navbar} from '../';
import './Home.css';

function App() {
    const menuItems = [
        { label: 'Home', link: '#home'},
        { label: 'Welcome', link: '#collabkart' },
        { label: 'Features', link: '#features' },
        { label: 'Blogs', link: '#blog' },
        { label: 'About', link: '/about' },
        { label: 'Explore More', link: '/blogs' }
    ];

    return (
            <div className="App">
                <div className="gradient__bg">
                    <Navbar menuItems={menuItems}/>
                    <Header></Header>
                </div>
                <Brand/>
                <WhatCollabKart/>
                <Features/>
                <CTA/>
                <Blog/>
                <Footer/>
            </div>
    );
}

export default App;
