import React from 'react';
import {BrowserRouter as Router, Route, Routes} from 'react-router-dom';

import About from './components/About/About';
import './App.css';
import Home from "./components/Home/Home";
import Blogs from './components/Blogs/BlogHomePage';
import BlogPage from './components/Blogs/BlogPage';

function App() {
  return (
      <Router>
        <Routes>
          <Route path='/' exact Component={Home}/>
          <Route path='/about' exact Component={About}/>
          <Route path='/blogs' exact Component={Blogs}/>
          <Route path='/blog/:id' exact Component={BlogPage}/>
        </Routes>
      </Router>
  );
}

export default App;
